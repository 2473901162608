export const contentTableConfig = {
  propList: [
    {
      prop: 'terminalId',
      label: 'terminal.id'
    },
    {
      prop: 'logType',
      label: 'terminal.log-type'
    },
    {
      prop: 'remark',
      label: 'general.remark'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'general.creation-date'
    },
    {
      prop: 'operations',
      label: 'common.app-operation',
      width: '150',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
